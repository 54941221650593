import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import styled from 'styled-components'
import Img from 'gatsby-image'
import SkillGroup from '../components/SkillGroup';

const RootSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Root = styled.div`
  background: var(--content-bg);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--shadow-on-bg);
  border: 2px solid var(--input-border-dark);
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  >h2 {
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
    color: var(--title);
  }
  >hr {
    width: 100%;
    height: 4px;
    background: var(--input-border);
    border: none;
    border-radius: 4px;
    margin: 20px 0 0;
  }
  @media (min-width: 768px) {
    padding: 40px;
  }
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
    >h1 {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    margin: 20px 0;
    color: var(--title);
  }
  >p {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    text-align: justify;
    margin: 0;
  }
  @media (min-width: 768px) {
    display: block;
    >h1 {
      margin: 0 0 20px;
      text-align: left;
      font-size: 35px;
    }
  }
`

const ProfilePicture = styled(Img)`
  max-width: 50%;
  width: 50%;
  max-height: 50%;
  height: 50%;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  vertical-align: top;
  @media (min-width: 768px) {
    min-width: 170px;
    width: 170px;
    max-width: 170px;
    height: 170px;
    min-height: 170px;
    max-height: 170px;
    margin: 0 20px 0 0;
    float: left;
  }
`

const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

const SkillBox = styled.div`
  background: var(--bg);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--shadow-on-bg);
  border: 2px solid var(--input-border);
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  >h3 {
    font-weight: 400;
    margin: 0;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    max-width: 360px;
    width: 100%;
    margin-bottom: 0;
    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 20px;
    }
  }
`

export default function AboutPage({ data }) {

  const content = data.markdownRemark.frontmatter

  return (
    <Layout>
      <RootSpace>
        <Root>
          <Information>
            <ProfilePicture fluid={content.profilePic.childImageSharp.fluid} alt="Profile picture" />
            <h1>{content.title}</h1>
            <p>{content.description}</p>
          </Information>
          <hr />
          <h2>Skills</h2>
          <SkillsContainer>
            <SkillBox>
              <h3>Languages</h3>
              {content.skills.languages.map((language, i) => (
                <SkillGroup key={`${language.name}${language.amount}`}
                            skillName={language.name}
                            skillProgress={`${language.amount}%`}
                            index={i}/>
              ))}
            </SkillBox>
            <SkillBox>
              <h3>Technologies</h3>
              {content.skills.technologies.map((tech, i) => (
                <SkillGroup key={`${tech.name}${tech.amount}`}
                            skillName={tech.name}
                            skillProgress={`${tech.amount}%`}
                            index={i}/>
              ))}
            </SkillBox>
          </SkillsContainer>
        </Root>
      </RootSpace>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        profilePic {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        description
        skills {
          languages {
            name
            amount
          }
          technologies {
            name
            amount
          }
        }
      }
    }
  }
`
