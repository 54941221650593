import React from 'react'
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    span {
      visibility: visible;
      transform: translate(40px, -50%);
      opacity: 1;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
  >p {
    margin: 0 20px 0 0;
    font-weight: 300;
    font-size: 18px;
    text-align: right;
    flex-basis: 50%;
  }
  @media (min-width: 375px) {
    >p {
      flex-basis: 45%;
    }
  }
  @media (min-width: 425px) {
    >p {
      flex-basis: 35%;
    }
  }
`

const ProgressContainer = styled.div`
  background: var(--bg-darker);
  height: 15px;
  border-radius: 8px;
  box-shadow: var(--shadow-on-bg);
  flex-basis: 50%;
  >div {
    height: 100%;
  }
  @media (min-width: 375px) {
    flex-basis: 55%;
  }
  @media (min-width: 425px) {
    flex-basis: 65%;
  }
`

const Progress = styled.div`
  position: relative;
  background: #3FA9F5;
  height: 100%;
  border-radius: 8px;
  width: 0;
  animation: fill .3s ease ${props => (0.1 * props.index)}s forwards;
  @keyframes fill {
    from { width: 10%; }
    to { width: 100%; }
  }
  span {
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    visibility: hidden;
    opacity: 0;
    width: 45px;
    text-align: center;
    position: absolute;
    top: 50%;
    right: -20px;
    background: var(--accent-dark);
    padding: 5px;
    border-radius: 8px;
    box-shadow: var(--shadow-large);
    cursor: default;
    transform: translate(0, -50%);
    color: #fff;
    transition: visibility .3s,
                opacity .3s,
                transform .3s;
    &::after {
      content: "";
      position: absolute;
      background: var(--accent-dark);
      transform: rotate(45deg);
      left: -2px;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 8px;
      height: 8px;
      z-index: -1;
    }
  }
`

const SkillGroup = (props) => (
  <Root>
    <p>{ props.skillName }</p>
    <ProgressContainer>
      <div style={{ width: props.skillProgress }}>
        <Progress index={props.index}>
          <span>{props.skillProgress}</span>
        </Progress>
      </div>
    </ProgressContainer>
  </Root>
)

export default SkillGroup
